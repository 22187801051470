export function getQueryVariable(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}
export function getQueryStringArgs(url) {
  // const url = window.location.href
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
  let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1];
  }
  return obj

}

export function isAppInstalled(url_scheme) {
  
  // var scheme = "paytmmp://"; // 替换为你想检测的应用程序的scheme
  // let scheme = url_scheme

  // // 创建一个隐藏的iframe
  // var iframe = document.createElement("iframe");
  // iframe.style.display = "none";

  // // 通过iframe的src将scheme传递给浏览器
  // iframe.src = scheme;
  // document.body.appendChild(iframe);

  // // 设置定时器，在一定时间后检查应用程序是否安装
  // var timer = setTimeout(function () {
  //   // 应用程序未安装的处理逻辑
  //   alert("应用程序未安装");
  // }, 3000); // 设置3秒超时

  // // 页面重新获得焦点时，取消定时器
  // window.onblur = function () {
  //   clearTimeout(timer);
  // }
  return url_scheme;
}

// 检测是否支持 Universal Links
export function isUniversalLinkSupported() {
  return document.createElement("a").hasAttribute("ping");
}
