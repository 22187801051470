import { getTripOrderByOrderId } from '@/api/api'
import _validate from "@/utils/validate";
import { getQueryStringArgs } from '@/utils/index';



class Esypay { 
    tripOrder = {};

    payment(orderid) { 
        // this.tripOrder = getQueryStringArgs("upi://pay?pa=EZULIXONLINE@ybl&pn=Ezulix&am=2.00&mam=2.00&tr=P1712712102510260226&tn=Payment%20for%20P1712712102510260226&mc=5816&mode=04&purpose=00&utm_campaign=B2B_PG&utm_medium=EZULIXONLINE&utm_source=P1712712102510260226");
        return new Promise((resolve, reject) => { 
            getTripOrderByOrderId(orderid).then(res => {
                if (res.qr === undefined || _validate.empty(res.qr)) {
                    return { code: 0, msg: "Qr Create Error" };
                }
                this.tripOrder = getQueryStringArgs(res.qr);
                resolve(res);
            }).catch((err) => { 
                reject(err)
            })
        })
    }

    getPaymentUrl(paytype) { 
        let uri = '';
        console.log('this.tripOrder');
        console.log(this.tripOrder);
        
        
        switch (paytype) {
            case "paytm":
                uri = this.paytm(this.tripOrder)
                break;
            case "phonepe":
                uri = this.phonepe(this.tripOrder)
                break;
            case "gpay":
                uri = this.googlePay(this.tripOrder)
                break;
            case "mobikwik":
                uri = this.mobikwik(this.tripOrder)
                break;
            case "bhim":
                uri = this.bhim(this.tripOrder)
                break;
        }
        return uri;
        
    }
    paytm(items) {
        //临时处理
        let str = "paytmmp://pay?";
        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                const element = items[key];
                // console.log(key + " -> " + element);
                str += key + "=" + element + "&";
            }
        }
        return str = str.slice(0, -2)
    }

    phonepe(items) {

        //临时处理
        let str = "phonepe://pay?";
        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                const element = items[key];
                // console.log(key + " -> " + element);
                str += key + "=" + element + "&";
            }
        }
        return str = str.slice(0, -2)
    }

    googlePay(items) {
        //临时处理
        let str = "tez://upi/pay?";
        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                const element = items[key];
                // console.log(key + " -> " + element);
                str += key + "=" + element + "&";
            }
        }
        return str = str.slice(0, -2)
    }

    mobikwik(items) {
        //临时处理
        let str = "mobikwik://open?";
        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                const element = items[key];
                // console.log(key + " -> " + element);
                str += key + "=" + element + "&";
            }
        }
        return str = str.slice(0, -2)

    }

    bhim(items) {
        //临时处理
        let str = "bhim://mandate?";
        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                const element = items[key];
                // console.log(key + " -> " + element);
                str += key + "=" + element + "&";
            }
        }
        return str = str.slice(0, -2)
    }
} 


export default new Esypay();


