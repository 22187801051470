<template>
  <div class="main">
    <div class="header">
      <div class="money">
        <span class="currency">₹</span>{{ amount }}
      </div>
      <p class="desc">The amount you need to pay</p>
      <p class="desc">OrderlD:{{ orderId }}</p>
    </div>


    <div class="content" v-if="mateType === 'default'">
      <p class="payUsing">PAY USING</p>
      <!-- <p class="payUsing" style="">Please Make Sure You Have Installed The App </p> -->
      <div class="payContainer">
        <div class="row">
          <div class="col-6" @click="changePayment('paytm')">
            <div :class="['payItem', curpayment === 'paytm' ? 'active' : '']">
              <img src="@/assets/images/paytmIcon-78b03fb3.png" alt="paytm" />
              <p>Paytm</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'paytm'">
                <span class="lbl"></span>
              </label>
            </div>
          </div>

          <div class="col-6" @click="changePayment('gpay')">
            <div :class="['payItem', curpayment === 'gpay' ? 'active' : '']">
              <img src="@/assets/images/gpayIcon-f5494724.png" alt="gpay" />
              <p>Google Pay</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'gpay'">
                <span class="lbl"></span>
              </label>
            </div>
          </div>
          <div class="col-6" @click="changePayment('phonepe')">
            <div :class="['payItem', curpayment === 'phonepe' ? 'active' : '']">
              <img src="@/assets/images/phonepeIcon-439507ba.png" alt="phonepe" />
              <p>PhonePe</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'phonepe'">
                <span class="lbl"></span>
              </label>
            </div>
          </div>

          <div class="col-6" @click="changePayment('bhim')">
            <div :class="['payItem', curpayment === 'bhim' ? 'active' : '']">
              <img src="@/assets/images/bhim-icon.jpg" alt="bhim" />
              <p>BHIM</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'bhim'">
                <span class="lbl"></span>
              </label>
            </div>
          </div>

          <!-- <div class="col-6" @click="changePayment('mobikwik')">
            <div :class="['payItem', curpayment === 'mobikwik' ? 'active' : '']">
              <img src="@/assets/images/mobikwik.jpg" alt="mobikwik" style="margin-left: 0.7rem" />
              <p>Mobikwik</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'mobikwik'">
                <span class="lbl"></span>
              </label>
            </div>
          </div> -->


        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div class="content" v-if="mateType === 'virtual'">
      <p class="payUsing">Nomor Rekening Virtual</p>
      <!-- <p class="payUsing" style="">Please Make Sure You Have Installed The App </p> -->
      <div class="payContainer">
        <div class="row">


          <div class="col-6">
            <div :class="['payItem', '']">
              &nbsp;&nbsp;
              <p>NO:{{ resData.channelOrderNo }}</p>
              <label>
                <!-- <button @click="copyTransactionId(resData.channelOrderNo)">Salin</button> -->
                <span class="lbl"></span>
              </label>
            </div>
          </div>

          <!-- <div class="col-6" @click="changePayment('mobikwik')">
            <div :class="['payItem', curpayment === 'mobikwik' ? 'active' : '']">
              <img src="@/assets/images/mobikwik.jpg" alt="mobikwik" style="margin-left: 0.7rem" />
              <p>Mobikwik</p>
              <label>
                <input name="radio1" type="radio" :checked="curpayment === 'mobikwik'">
                <span class="lbl"></span>
              </label>
            </div>
          </div> -->


        </div>
        <div class="clear"></div>
      </div>
    </div>




    <div class="footer">
      <div class="footLogo">
        <p>Powered by pay.aio-pay.com ver:{{ software_version }}</p>
        <img src="../../assets/images/upi.jpg">
      </div>

      <hr />

      <div class="paragraph">
        <p>Tips:</p>
        <p>lf your payment is successful and your account has not increased the amountplease prowide your order number
          and
          payment voucher to contact us assoon as possilble.</p>
      </div>
      <div class="mail">
        <i class="envelope"></i>
        {{ $config.SYS_AIOPAY_EMAIL }}
      </div>

      <div class="make" @click="submitPayment" v-if="mateType === 'default'">
        <button :disabled="submitState.buttonDisabled">Make Payment</button>
      </div>
      <div class="make" @click="copyTransactionId(resData.channelOrderNo)" v-if="mateType === 'virtual'">
        <button>Salin</button>
      </div>

    </div>
    <div class="modal" v-show="dialogState.state">
      <div class="modal-main">
        <div class="modal-content">
          <p>Has the payment been completed?</p>
        </div>
        <div class="modal-footer">
          <button @click="dialogState.state = false; dialogState.singleTimerSeconds = 0;">Cancel</button>
          <button @click="toPayResulet('success')" :disabled="payButtonDisabled">Confirm</button>
        </div>
      </div>
    </div>

    <div class="modal" v-show="paymentDialog.state">
      <div class="modal-main" style="padding:.1rem 0">
        <div class="modal-content">
          <div class="header" style="height: 2.5rem;border-radius:.3rem">
            <div class="money" style="padding-left: 3rem; margin-top: .3rem;">
              <span class="currency">₹</span>{{ amount }}
            </div>
          </div>
          <b style="font-size: 18px;">Are you sure to make the payment?</b>
          <br />
          <span style="font-size: 12px;">Please Make Sure You Have Installed The App</span>
        </div>
        <div class="modal-footer">

          <button @click="redirectPayment()" :disabled="paymentDialog.buttonDisabled">Yes</button>
          <button @click="paymentDialog.state = false;">No</button>
        </div>
      </div>
    </div>

    <div class="loading-modal" v-show="showLoading">
      <div class="box loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>


  </div>
</template>



<script>
import _validate from "@/utils/validate";
// import * as reconPayLink from '@/utils/reconPayLink';
import _esypay from '@/utils/channel/esypay';
import _recon from '@/utils/channel/recon';
import _SafexPay from '@/utils/channel/safexpay';
import * as _cookie from '@/utils/cookie'
import {
  getPayOrderInfoByPayOrderId
} from '@/api/api'

export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  components: {
    // Keyboard,
  },
  data: function () {
    return {
      mateType: "default",
      submitState: {
        buttonDisabled: true,
      },
      payButtonDisabled: false,
      paymentDialog: {
        state: false,
        paymentDialog: false,
      },
      dialogState: {
        state: false,
        singleTimerSeconds: 0,
        singleTimerId: null
      },
      paymentInstanceof: null,
      orderId: "",
      curpayment: "paytm",
      amount: "0", // 支付金额默认
      resData: {},
      payOrderInfo: {}, //订单信息,
      singleTimerId: null,
      singleTimerSeconds: 0,
      showLoading: false,
      software_version:"",
    };
  },
  created() {
    const _this = this
    _this.showLoading = true;
    _this.software_version = process.env.VUE_APP_VERSION;

    _cookie.setByKey("pay_order_info", {})
    this.orderId = this.$route.query.tn;
    this.getPaymentOrderData();
    this.payOrderInfo.orderId = this.orderId
  },

  mounted() {
    const _this = this
    // this.payment(false); //获取订单信息 & 调起支付插件
    this.beginSingleTimerSeconds()

    setTimeout(() => {
      this.showLoading = false
    }, 500)
    window.addEventListener("mousedown", () => {
      _this.dialogState.singleTimerSeconds = 0
    });
    window.addEventListener("keydown", () => {
      _this.dialogState.singleTimerSeconds = 0
    });

  },

  methods: {
    copyTransactionId(value) {
      // 创建一个临时的文本区域
      const textArea = document.createElement('textarea');
      textArea.value = value;

      // 将文本区域添加到页面中
      document.body.appendChild(textArea);

      // 选中并复制文本
      textArea.select();
      document.execCommand('copy');

      // 移除临时文本区域
      document.body.removeChild(textArea);

      alert('Replicating Success!');
    },
    getPaymentOrderData() {
      const _this = this;

      getPayOrderInfoByPayOrderId(this.orderId).then(res => {
        console.log('succ payorderinfo res ');
        console.log(res);
        console.log(`output->res.wayCode.toLowerCase()`, res.wayCode.toLowerCase())
        _this.resData = res;
        console.log(`output->_this.resData`, _this.resData)
        switch (res.wayCode.toLowerCase()) {
          case "esypay":
            _this.paymentInstanceof = _esypay
            break;
          case "reconpay":
            _this.paymentInstanceof = _recon
            _this.paymentInstanceof.setOrderId(res.payOrderId)
            _this.paymentInstanceof.setAmt(res.amt)
            _this.paymentInstanceof.setPa(res.upiname)
            _this.paymentInstanceof.setPn(res.upiid)
            _this.paymentInstanceof.setTn("")
            break;
          case "safex_pay":
            _this.paymentInstanceof = _SafexPay
            _this.paymentInstanceof.setOrderId(res.payOrderId)
            _this.paymentInstanceof.setAmt(res.amt)
            _this.paymentInstanceof.setPa(res.pay_ext.pa)
            _this.paymentInstanceof.setPn(res.pay_ext.pn)
            _this.paymentInstanceof.setTn(res.pay_ext.tn)

            break;
          case "mika_gva":

            _this.mateType = 'virtual';
            break;
          default:
            throw ('Order Query Fail, Please Refresh And Try Again');
        }

        this.amount = parseFloat(res.amt);
        this.submitState.buttonDisabled = false;
        this.payOrderInfo.am = this.amount
        //临时存储payOrderInfo
        _cookie.setPayOrderData(this.payOrderInfo)
        console.log(this.submitState.buttonDisabled);

      }).catch((error) => {
        console.log(`output->error`, error)
        alert("Order Expiration , error : Order Not Exists");
      })
    },
    isAppInstalled(appURLScheme) {
      const _this = this
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // 判断app是否已安装
        var isAppInstalled = false;
        console.log('succ appURLScheme');
        console.log(appURLScheme);



        // 创建一个隐式唤醒的链接来尝试打开App
        var appLink = document.createElement("a");
        appLink.href = appURLScheme
        appLink.style.display = "none";
        document.body.appendChild(appLink);

        // 添加事件监听，检查是否成功打开App
        appLink.addEventListener("click", function () {
          isAppInstalled = true;
        });

        // 触发点击事件并移除链接
        appLink.click();
        document.body.removeChild(appLink);

        // alert(isAppInstalled)
        // 检查是否安装了App
        if (!isAppInstalled) {
          // App未安装，弹出确认对话框
          alert("尚未安装App，是否需要下载")
          // if (shouldDownload) {
          //   // 根据设备类型跳转到相应的应用商店地址
          //   // 使用localStorage保存当前页面URL
          //   localStorage.setItem("prevPageURL", window.location.href);
          // }
        } else {
          // App已安装，保持页面不变
          // alert("App已启动！");
          setTimeout(() => {
            _this.jumpPayWaitDesk();
          }, 1000)

          // 在500毫秒后返回之前的页面
          setTimeout(function () {
            var prevPageURL = localStorage.getItem("prevPageURL");
            if (prevPageURL) {
              localStorage.removeItem("prevPageURL");
              history.replaceState(null, null, prevPageURL);
              alert("返回页面成功！");
            }
          }, 500);
        }
      }

    },
    // 开启定时器 检测当前是否有操作 来进行弹窗
    beginSingleTimerSeconds() {
      const _this = this

      if (_this.dialogState.singleTimerId === null) {
        _this.dialogState.singleTimerId = setInterval(() => {

          if (_this.dialogState.singleTimerSeconds >= 20) {
            _this.dialogState.state = true;
          }
          // console.log('succ beginSingleTimerSeconds : ' + _this.dialogState.singleTimerSeconds);
          _this.dialogState.singleTimerSeconds++
        }, 1000)
      }
    },
    toPayResulet(type) {
      const _this = this
      _this.payButtonDisabled = true;

      getPayOrderInfoByPayOrderId(_this.orderId).then(res => {
        _this.payButtonDisabled = false
        //支付成功
        if (res.state === 2) {
          _this.clearSignleTimerId()
          //   //跳转订单支付succ页面
          _this.$router.push({
            name: 'CashierPayResultSuccess',
            query: {
              orderId: _this.orderId,
              utr: res.referenceId !== undefined ? res.referenceId : '',
              am: _this.payOrderInfo.am
            }
          })
          return;
        }
        // 支付失败
        if ([3, 4, 6].includes(res.state) || type === 'cancel') {
          _this.clearSignleTimerId()
          _this.$router.push({
            name: 'CashierPayResultFail',
            query: {
              errInfo: 'Order Payment Fail',
              orderId: _this.orderId,
              am: _this.payOrderInfo.am
            }
          })
          return;
        }
      })
      _this.singleTimerSeconds += 3;
    },
    clearSignleTimerId() {
      if (this.singleTimerId !== null) {
        clearInterval(this.singleTimerId)
        this.singleTimerId = null
      }
      if (this.dialogState.singleTimerId !== null) {
        clearInterval(this.dialogState.singleTimerId)
        this.dialogState.singleTimerId = null
      }

    },
    submitPayment() {
      const _this = this
      _this.showLoading = true

      //调整逻辑，完成三方信息组装

      //发起三方订单请求
      _this.paymentInstanceof.payment(_this.payOrderInfo.orderId).then(res => {
        console.log('succ');
        _this.paymentDialog.state = true
        _this.showLoading = false
      }).catch(err => {
        console.log('submit trip order error');
        console.log(err);
        _this.showLoading = false
        alert("Order Expiration , Error : " + "Plase Request Fail");
      })
    },
    jumpPayWaitDesk() {
      const _this = this;
      _this.$router.push({
        name: 'CashierPayWait',
        query: {
          orderId: _this.orderId
        }
      })
    },
    redirectPayment() {
      const _this = this;
      // this.paymentDialog.buttonDisabled = true
      let url = this.genPaymentProtocol();

      this.isAppInstalled(url)

      //3秒后关闭弹窗
      setTimeout(() => { _this.paymentDialog.state = false, this.paymentDialog.buttonDisabled = false }, 3000);

      // this.dialogState.state = true;
      //组装支付链接q
      // let url = this.genPaymentProtocol();
      //弹出窗口提醒
      //openAppWithScheme（）
      // this.isAppInstalled(url)
    },
    changePayment(value) {
      this.curpayment = value;
      this.dialogState.singleTimerSeconds = 0
    },

    genPaymentProtocol() {
      return this.paymentInstanceof.getPaymentUrl(this.curpayment);
      // paytmmp://pay?pa=cfmer.3959rahultest03@icici&pn=RahulT&tn=Note&am=1&cu=INR
    },
  },
};
</script>




<style>
body,
html {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #eee;
}
</style>
<style scoped>
* {
  font-family: '微软雅黑';
}

.main {
  min-height: 100vh;
}

.header {
  /* Safari 5.1 - 6.0 */
  background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
  /* Opera 11.1 - 12.0 */
  background: -o-linear-gradient(to right, #367af7, #76a6ff);
  /* Firefox 3.6 - 15 */
  background: -moz-linear-gradient(to right, #367af7, #76a6ff);
  background: linear-gradient(to right, #367af7, #76a6ff);
  background-color: #367af7;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  margin-top: .4rem;
  padding: 0 .4rem;
}

.money {
  color: #fff;
  font-size: 102px;
  padding-left: .4rem;
  font-family: '黑体';
  font-weight: 600;
  margin-top: .6rem;
}

.money .currency {
  font-size: 62px;
}

.desc {
  font-size: 24px;
  color: #d9e4ff;
  padding-left: 0.4rem;
  margin: 0 0 0 0;
}

.col-6 {
  width: calc(50% - 24px);
  padding: 0 12px;
  float: left;
}

.content>p {
  padding: 0;
  margin: 0;
  font-size: .4rem;
  text-align: left;
  color: #939393;
}

.row {
  width: 100%;
  margin-left: -12px;
  margin-right: -12px;
}

.payContainer {
  background-color: #fff;
  border-radius: 14px;
  margin-top: .4rem;
}

.payContainer .payItem {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  position: relative;
  height: 1.8rem;
  cursor: pointer;
}

.payContainer .payItem.active {
  background-color: rgb(221, 247, 236);
}

.payContainer .payItem>img {
  max-height: 40px;
  max-width: 160px;
  /* width: auto; */
  width: 70px;
  margin-left: 30px;
}

.payContainer .payItem>label {
  font-size: 20px;
  position: absolute;
  right: 40px;
}

.payContainer .payItem>p {
  font-size: 25px;
  margin-left: 12px;
  color: rgb(0, 0, 0);
}

.payContainer .payItem>span {
  margin-left: 30px;
  font-size: 20px;
}

.clear {
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
}

/* anquanmiaoshu */
.describe {
  width: 100%;

}

.describe img {
  display: inline-block;
  width: 20px;
  height: auto;
  margin-right: 12px;
}

.describe p {
  display: inline-block;
  font-size: 0.366667rem;
  color: #858585;
}


.footer {
  width: 100%;
  font-size: .4rem;
  margin-top: 2rem;
  padding-bottom: 200px;
}

.footer .footLogo {
  text-align: center;
}

.footer .footLogo p {
  margin: 0;
  color: #5d5d5d;
  font-size: .2rem;

}

.footer .footLogo img {
  width: 1.0rem;
  height: auto;
  margin-top: .1rem;
}

.footer .make {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
  width: 100%;
  background-color: #eee;
}

.footer .make>button {
  /* Safari 5.1 - 6.0 */
  background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
  /* Opera 11.1 - 12.0 */
  background: -o-linear-gradient(to right, #367af7, #76a6ff);
  /* Firefox 3.6 - 15 */
  background: -moz-linear-gradient(to right, #367af7, #76a6ff);
  background: linear-gradient(to right, #367af7, #76a6ff);
  background-color: #367af7;
  color: #fff;
  font-size: .6rem;
  border: none;
  border-radius: .6rem;
  display: block;
  width: 90%;
  height: 1.4rem;
  margin: .6rem auto;
}

.footer .paragraph {
  text-align: left;
  padding: 0 .6rem;
  font-size: .2rem;
  color: #939393;
}

.footer .mail {
  text-align: left;
  padding: 0 0.6rem;
  font-size: .2rem;
}

.footer hr {
  margin: .2rem .6rem;
  border: 1px solid #fff;
}

.envelope::after {
  font-family: fontAwesome;
  content: "\f0e0";
  font-weight: normal;
  font-style: normal;
  font-size: .4rem;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee40;
}

.modal-main {
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 50% auto;
  transform: translate(-50%, -50%);
  width: 90%;
  background: #fff;
  border-radius: .4rem;
  padding: .6rem 0;
  box-shadow: .1rem .1rem .1rem #00000060;
}

.modal-content p {
  font-size: .5rem;
  font-weight: 600;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.modal-footer>button {
  /* Safari 5.1 - 6.0 */
  background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
  /* Opera 11.1 - 12.0 */
  background: -o-linear-gradient(to right, #367af7, #76a6ff);
  /* Firefox 3.6 - 15 */
  background: -moz-linear-gradient(to right, #367af7, #76a6ff);
  background: linear-gradient(to right, #367af7, #76a6ff);
  background-color: #367af7;
  color: #fff;
  font-size: .4rem;
  border: none;
  border-radius: .3rem;
  display: block;
  width: 3rem;
  height: .8rem;
  margin: .6rem auto;
}

@media (max-width: 640px) {
  .col-6 {
    width: 100%;
  }

}

@media (min-width: 640px) {
  .header {
    height: 80px;
    align-items: center;
  }

  .header .money {
    margin-top: 0;
    font-size: 30px;
  }

  .header .money .currency {
    font-size: 24px;
  }

  .header .desc {
    margin-top: 0;
    font-size: 10px;
  }

  .payUsing {
    font-size: 12px !important;
  }




  .content {
    margin-top: 6px
  }

  .payContainer {
    background-color: #eee;
    margin-top: 6px;
  }

  .payContainer .row .col-6 {
    width: calc(33.333% - 24px);
    padding: 0 12px;
  }



  .payContainer .payItem {
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 8px !important;
    height: 50px !important;
  }

  .payContainer .payItem>img {
    max-height: 50px !important;
    max-width: 50px !important;
    width: auto;
    margin-left: 6px;
  }

  .payContainer .payItem>p {
    font-size: 12px;
    margin-left: 6px;
  }

  .payContainer .payItem>label {
    right: 0;
    font-size: 14px;
  }



  .footer {
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
  }

  .footer .paragraph,
  .footer .mail {
    display: none;
  }

  .footer .footLogo>p {
    font-size: 10px;
  }

  .footer .footLogo img {
    width: 40px;
    margin-top: 0;
  }

  .footer hr {
    margin: 2px 0.6rem;
    ;
  }

  .footer .make {
    position: relative;
  }

  .footer .make>button {
    border-radius: 12px;
    height: 40px !important;
    margin: 6px auto;
    font-size: 18px;
  }

  .modal-main {
    width: 60%;
    border-radius: 12px;
    padding: 12px 0;
    top: 30%;
  }

  .modal-content p {
    font-size: 18px;
  }

  .modal-footer>button {
    font-size: 12px;
    border: none;
    border-radius: 12px;
    display: block;
    width: 80px;
    height: 30px;
    margin: 20px auto;
  }
}
</style>
<style scoped>
/* 单选样式 */
input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: .5rem;
  height: .5rem;
  cursor: pointer;
}

label input[type=radio] {
  z-index: -100 !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

input[type=radio]:checked,
input[type=radio]:focus {
  outline: none !important;
}

input[type=radio]+.lbl {
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: .52rem;
  min-height: .5rem;
  min-width: .5rem;
  font-weight: normal;
  cursor: pointer;
  margin-right: 10px;
}

input[type=radio]+.lbl::before {
  cursor: pointer;
  font-family: fontAwesome;
  font-weight: normal;
  font-size: .5rem;
  color: #FFF;
  content: "\a0";
  background-color: #FAFAFA;
  border: 1px solid #C8C8C8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  /*, inset 0px -15px 10px -12px rgba(0,0,0,0.05);*/
  border-radius: 0;
  display: inline-block;
  text-align: center;
  height: 16px;
  line-height: .5rem;
  min-width: .5rem;
  margin-right: 0.1rem;
  position: relative;
  top: -1px;
}

input[type=radio]:checked+.lbl::before {
  display: inline-block;
  content: '\f00c';
  color: #fff;
  background-color: rgb(15, 100, 254);
  border-color: rgb(15, 100, 254);

}

input[type=radio]:active+.lbl::before,
input[type=radio]:checked:active+.lbl::before {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

input[type=radio]:disabled+.lbl::before,
input[type=radio][disabled]+.lbl::before,
input[type=radio].disabled+.lbl::before {
  background-color: #DDD !important;
  border-color: #CCC !important;
  box-shadow: none !important;
  color: #BBB;
}

.radio label input[type=radio]+.lbl {
  margin-left: -10px;
}

input[type=radio]+.lbl::before {
  border-radius: 100%;
  font-size: .4rem;
  font-family: FontAwesome;
  text-shadow: 0 0 1px #fff;
  line-height: 0.51rem;
  height: .51rem;
  min-width: .51rem;
}


@media (min-width: 640px) {
  input[type=radio] {
    width: 16px;
    height: 16px;
  }

  input[type=radio]+.lbl {
    line-height: 16px;
    min-height: 16px;
    min-width: 16px;
    margin-right: 10px;
  }

  input[type=radio]+.lbl::before {
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    min-width: 16px;
    margin-right: 4px;
  }

  input[type=radio]+.lbl::before {
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    min-width: 16px;
  }
}
</style>
<style scoped>
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #55555540;
}

.loading {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.loading span {
  display: block;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  animation: effect .6s linear infinite alternate;
  transform: scale(0);
  background: #002b73;
}

@keyframes effect {
  100% {
    transform: scale(1);
  }
}

.loading span:nth-child(1) {
  animation-delay: .1s;
}

.loading span:nth-child(2) {
  animation-delay: .2s;
}

.loading span:nth-child(3) {
  animation-delay: .3s;
}

.loading span:nth-child(4) {
  animation-delay: .4s;
  background: #00b9f5;
}

.loading span:nth-child(5) {
  animation-delay: .5s;
  background: #00b9f5;
}
</style>
