import {
    getReconPayOtpByPayOrderId
} from '@/api/api'

/** demo : paytmmp://pay?pa=cfmer.3959rahultest03@icici&pn=RahulT&tn=Note&am=1&cu=INR */

class SafexPay {
    tripOrder = {}

    constructor() {
    }

    payment(orderid) {
        // this.tripOrder = getQueryStringArgs("upi://pay?pa=EZULIXONLINE@ybl&pn=Ezulix&am=2.00&mam=2.00&tr=P1712712102510260226&tn=Payment%20for%20P1712712102510260226&mc=5816&mode=04&purpose=00&utm_campaign=B2B_PG&utm_medium=EZULIXONLINE&utm_source=P1712712102510260226");
        return new Promise((resolve, reject) => {
            return resolve(null);

            getReconPayOtpByPayOrderId(orderid).then(res => {
                this.setTn(res.otp)
                // reject('err')
                resolve(res);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    setOrderId(value) {
        this.tripOrder.orderid = value;
    }
    setAmt(value) {
        this.tripOrder.am = value;
    }
    setPa(value) {
        this.tripOrder.pa = value;
    }
    setPn(value) {
        this.tripOrder.pn = value;
    }
    setTn(value) {
        this.tripOrder.tn = value;
    }

    query() { }

    getPaymentUrl(paytype) {
        let uri = '';
        switch (paytype) {
            case "paytm":
                uri = this.paytm(this.tripOrder)
                break;
            case "phonepe":
                uri = this.phonepe(this.tripOrder)
                break;
            case "gpay":
                uri = this.googlePay(this.tripOrder)
                break;
            case "mobikwik":
                uri = this.mobikwik(this.tripOrder)
                break;
            case "bhim":
                uri = this.bhim(this.tripOrder)
                break;
        }
        return uri;
    }

    paytm(order) {
        return decodeURIComponent("paytmmp://pay?" + "pa=" + decodeURIComponent(order.pa) + "&pn=" + order.pn + "&am=" + order.am + "&tn=" + order.tn + "&cu=INR")
    }

    phonepe(order) {
        return "phonepe://pay?pa=" + order.pa + "&pn=" + order.pn + "&tn=" + order.tn + "&am=" + order.am + "&cu=INR"
    }

    googlePay(order) {
        return "tez://upi/pay?pa=" + order.pa + "&pn=" + order.pn + "&tn=" + order.pn + "&am=" + order.am + "&cu=INR"
    }

    mobikwik(order) {
        return "mobikwik://open?pa=" + order.pa + "&pn=" + order.pn + "&mc=" + order.orderId + "&tr=" + order.orderId + "&mode=03&am=" + order.am + "&cu=INR"
    }

    bhim(order) {
        return "bhim://mandate?pa=" + order.pa + "&pn=" + order.pn + "&mc=" + order.orderId + "&tr=" + order.orderId + "&mode=03&am=" + order.am + "&cu=INR";
    }

}


export default new SafexPay();
